exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assetside-data-protection-policy-tsx": () => import("./../../../src/pages/assetside/data-protection-policy.tsx" /* webpackChunkName: "component---src-pages-assetside-data-protection-policy-tsx" */),
  "component---src-pages-assetside-index-tsx": () => import("./../../../src/pages/assetside/index.tsx" /* webpackChunkName: "component---src-pages-assetside-index-tsx" */),
  "component---src-pages-assetside-primeport-case-study-tsx": () => import("./../../../src/pages/assetside/primeport-case-study.tsx" /* webpackChunkName: "component---src-pages-assetside-primeport-case-study-tsx" */),
  "component---src-pages-assetside-support-services-policy-tsx": () => import("./../../../src/pages/assetside/support-services-policy.tsx" /* webpackChunkName: "component---src-pages-assetside-support-services-policy-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-nala-tsx": () => import("./../../../src/pages/nala.tsx" /* webpackChunkName: "component---src-pages-nala-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */)
}

